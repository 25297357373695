<template>
  <div>
    <!-- Modal -->
    <Modal id="changeEmail" ref="changeEmail" size="md" title="Change Email">
      <div :class="[isSubmit?'pointer-event-none':'']">

<div class="">

  <b-col cols="12">
    <label for="user-email"  class="label m-0">Email</label>
    <div class="v-16-input-control">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
     <path d="M18.3334 5.00004C18.3334 4.08337 17.5834 3.33337 16.6667 3.33337H3.33335C2.41669 3.33337 1.66669 4.08337 1.66669 5.00004M18.3334 5.00004V15C18.3334 15.9167 17.5834 16.6667 16.6667 16.6667H3.33335C2.41669 16.6667 1.66669 15.9167 1.66669 15V5.00004M18.3334 5.00004L10 10.8334L1.66669 5.00004" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
     </svg>
       <input  v-model.trim="detail.email"   :disabled="isOTPSend"  id="user-email" type="email" class="pl-2" name="user-email" >
             </div>
   
      <span v-if="!emailState&&isSubmit" class="text-danger invalid-feedback d-block mt-1 ml-2">{{ error.email }}</span>
      <div class="text-right " v-if="isOTPSend">

        <span  class="secondary-effect change-btn" @click="isOTPSend=false">
          Change Email
        </span>
      </div>
   
  </b-col>
  <b-col cols="12" v-if="isOTPSend">
    <label for="verify-otp"  class="label m-0">Verify OTP:</label>
    <div class="v-16-input-control">
      
       <input  v-model.number="otp"
        placeholder="OTP"  id="verify-otp" type="number" class="" name="otp-email" >
             </div>
 
      <div class=" text-muted" style="font-size: 12px;">
        OTP sent to your {{ detail.email }}. Please enter your OTP and Verify Email.
      </div>
      
    </b-col>
  <b-col cols="12" class="d-flex justify-content-center mt-3">
             <Button @click="verifyOTP()" :isLoading="isSubmit&&otp"  v-if="isOTPSend">
    
      <span>Verify Email</span>
</Button>
    <Button @click="submitUserDetail()" :isLoading="isSubmit"  v-if="!isOTPSend" :disabled="!emailState">
    
      <span>{{detail.email?.toLowerCase()!=previousEmail.toLocaleLowerCase()?'Send Otp':'Save Changes'}}</span>
</Button>
  </b-col>
  

</div>
</div>
    </Modal>
  </div>
</template>

<script>

import {mapGetters} from "vuex";

import "vue-tel-input/dist/vue-tel-input.css";
import globalMixin from "../../../../GlobalMixins/getSignedURLMixin.js";
import axios from 'axios'
import Modal from '../../Layout/Modal.vue';
import Button from "../../Layout/Button.vue";



export default {
  props: {
    user: Object,
  },
  components:{
    Modal,
    Button
  },
   mixins: [globalMixin],
  data() {
    return {
      isOTPSend:false,
      otp:"",
      detail: { },
      isSubmit: false,
      previousEmail:'',
      phoneValid: false,
      phoneInputOption: {
        placeholder: "Enter Phone Number",
        maxlength: 15,
      },
      dropdown: {
        showSearchBox: true,
        showFlags: true,
        width: "390px",
      },
      error:{
        phone:'Please fill name field',
        email:'Please enter a valid email',
        isDuplicateEmail:false,
        name:false
      },
      isImageUpdating:false
    };
  },
watch:{
  user:{
    handler(){
      this.detail = {...this.user}
      this.previousEmail=this.user.email;
    },deep:true
  },
  detail:{
    handler(){
     // eslint-disable-next-line no-useless-escape
        const reg = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g);
      if (this.detail && this.isSubmit)
        if (reg.test(this.detail.email)) {
          this.error.email = "Please enter a valid email";
        }
    },
    deep:true
  }
},
  computed: {

    nameState() {
      if (this.detail.name && this.isSubmit)
        return this.detail.name.length >= 3;
      else return null;
    },

    emailState() {
      // eslint-disable-next-line no-useless-escape
      const reg = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g);
  
 return reg.test(this.detail.email)&&this.detail.email.toLowerCase()!=this.previousEmail.toLocaleLowerCase();
    
    },
  validateSubmission(){
   return this.emailState&&this.nameState&&(this.phoneValid||this.detail.phone=='')
  },
  ...mapGetters( ["getNavbarData"]),
     getUserType(){
      return this.getNavbarData.userType
    },
    disableInput(){
      let allowedUsers = ['web', 'bc']
       
       return allowedUsers.includes(this.getUserType) ? false : true

    }
  },
  methods: {
    triggerChangeImage(){
      this.$refs.uploadImage.inputUploadFile()
    },

   
    OpenModal() {
    this.$refs.changeEmail.open()
    },
   
    onValidation(e) {
      if (e.valid !== undefined) {
        this.phoneValid = !e.valid;
      } else {
        this.phoneValid = false;
      }
    },
    
     async verifyOTP(){
      this.isSubmit=true;

       try {
        let data = {
          otp: this.otp,
          new_email: this.detail.email,
         
        };
        const response = await axios.post(`/profile/update-email`, data);
        if (response.status) {
         if(response.data.data.email_changed)
         {
           this.$toasted.show("A verification email sent to new Mail", {
           theme: "toasted-primary",
           position: "bottom-center",
           duration: 2000,
         });
         this.closeModal()
         this.$emit('UpdateAccountData')
         }
          else{
            this.$toasted.show(response.data.message, {
           theme: "toasted-primary",
           position: "bottom-center",
           duration: 2000,
         });
          }
        }
      } catch (error) {
        if (error) {
          this.$toasted.show("Invalid OTP", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
        throw error;
      } finally{
        this.isSubmit=false;
      }
      
    },
    async submitUserDetail(){
      this.isSubmit=true;
       try {
        let data = {
         
          new_email: this.detail.email,
         
        };
        const response = await axios.post(`/profile/change-email`, data);
        if (response.status) {
          this.isOTPSend=true
          // this.closeModal()
          // this.$emit('UpdateAccountData')
        }
      } catch (error) {
        const errorArray = error.response?.data?.errors;

if (Array.isArray(errorArray)) {
  for (let a of errorArray) {
    this.$toasted.show(a, {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
  }
} else if (typeof errorArray === 'object' && errorArray !== null) {
  for (let key in errorArray) {
    if (Object.prototype.hasOwnProperty.call(errorArray, key)) {
      this.$toasted.show(errorArray[key], {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
    }
  }
}
        else {
          this.$toasted.show("Error occurred", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
        throw error;
      } finally{
        this.isSubmit=false;
      }
      
    }
  },
  mounted(){
      
  }
};
</script>
<style>
.pointer-event-none{
pointer-events: none;
}
.btn-submit {
  color: #ffffff;
  font-size: 14px;

  align-items: center;
  padding: 10px 16px;
  gap: 10px;

  width: 139px;
  height: 41px;

  background: #ffa201;
  border-radius: 8px;
}
.btn-submit-Disabled {

  background: #eee8f2 !important;
  color: #4d1b7e !important;
}

.vue-tel-input:focus-within {
  color: #495057;
  background-color: #fff;
  border-color: #f7f6f9;
  outline: 0;
  box-shadow: 0 0 0 0.1rem #4d1b7e;
}
.label {
  /* margin:0px; */
  
  font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #667085;
    margin: 0px;
}
</style>
<style scoped>
.change-btn:hover{
  text-decoration: underline;
  color: var(--secondary-color);
  cursor:pointer;
}
.secondary-effect{
font-size: 14px;
font-weight: 500;
line-height: 20px;
text-align: left;
color:var(--secondary-color);
}
.btn-submit:hover {
  color: #ffffff;
}
.btn-submit:active {
  background: #dd9315;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #f7f6f9;
  outline: 0;
  box-shadow: 0 0 0 0.1rem #4d1b7e;
}
.upload-image {
  text-decoration-line: underline;
  color: #ffa201;
  font-size: 14px;
  cursor: pointer;
}
.VerifyAccountEmailStyle {
  max-height: 80vh;
  overflow: hidden scroll;
  scroll-behavior: smooth;
}
.modal-heading {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  width: 100%;
}
.modal-content {
  border-radius: 20px;
  overflow: hidden;
}
.modal-btn {
  background: #ffa201;
  color: #ffffff;
  border: none;
}
.modal-btn:hover {
  color: #ffffff;
}

.btn-profile-seeting {
  border: 1px solid #ffa201;
  color: #ffa201;
}
.btn-profile-seeting:hover {
  border: 1px solid #ffa201;
  color: #ffffff;
  background: #ffa201;
}

.TitleHead {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 42px;

  color: #18191c;
}


</style>
